@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');
@import url('bootstrap/dist/css/bootstrap.min.css');

body {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
}

html, #root {
  height: 100vh;
}

.menu {
  top: 0;
  left: -160px;
  width: 210px;
  height: 100%;
  position: fixed;
  transition: all 0.2s ease-in-out;
  z-index: 10;
}

.menu--open {
  left: 0px;
  background: #fff;
}

.menu_bar {
  padding: 0.75rem 0;
  position: relative;
}

.menu_toggle {
  border: 0;
  background: #fff;
  position: absolute;
  right: 0;
  top: 12px;
}

.menu_close-icon span {
  background-color: #000;
  background-image: none !important;
  display: block;
  margin: 0.33rem 0;
  transition: all 0.2s ease-in-out;
  width: 30px;
  height: 0.175rem;
}

.menu_close-icon--open span:nth-child(1) {
  transform: translateY(0.5rem) rotate(-135deg);
}

.menu_close-icon--open span:nth-child(2) {
  transform: scale(0);
}

.menu_close-icon--open span:nth-child(3) {
  transform: translateY(-0.5rem) rotate(135deg);
}

.menu_link {
  font-weight: 500;
  font-size: 20px;
  color: #000;
  text-decoration: none;
}

.menu_links-wrap {
  padding: 2rem;
  position: relative;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
}

.main .locations {
  width: 100%;
  text-align: center;
  padding: 8px 0 0;
  font-size: 14px;
  flex-grow: 1;
}

.main .logo {
  width: 100%;
  max-width: 800px;
}

.main .logo_img {
  width: 100%;
}

.main .yelp {
  width: 80px;
  margin-bottom: 2rem;
}

.main .yelp_img {
  width: 100%;
}


.header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.services,
.about,
.contact {
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 16px;
  position: relative;
}

.services_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.services_table {
  margin-top: 36px;
  flex-grow: 3;
  padding-right: 36px;
}

.services_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
}

.services_row_extra_margin {
  margin-bottom: 16px;
}

.services_image {
  margin-top: 36px;
  max-width: 250px;
  flex-grow: 1;
}

.services_image img {
  width: 100%;
}

.about_img {
  float: right;
  margin-left: 24px;
}

.about_img2 {
  float: left;
  margin-right: 36px;
}

.contact_buttons {
  margin-top: 2rem;
  width: 100%;
}

.contact_textarea {
  border: 0;
  border-radius: 0.5rem;
  display: block;
  resize: none;
  padding: 1rem;
  width: 100%;
}

.contact_field {
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  max-width: 220px;
}

.contact_block {
  padding-bottom: 1rem;
}

.contact_block label {
  font-weight: 600;
}

.contact_block textarea {
  height: 100px;
  resize: none;
}

.contact_block .form-select,
.contact_block .form-control {
  border: solid 1px #a9a9a9 !important;
}

.contact_thank-you {
  background-color: 3fff;
  border-radius: 0.5rem;
  display: none;
  padding: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
}

.field {
  width: 100%;
  margin-top: 0.5rem;
}

.field input {
  border: solid 1px #a9a9a9;
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  width: 100%;
  height: 36px;
}

@media (max-width: 600px) {
  .main {
    padding: 3rem 1rem;
  }

  .about_img,
  .about_img2,
  .contact_img {
    max-width: 150px;
  }

  .contact_img {
    margin-top: 36px;
  }
}
